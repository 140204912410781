<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Agregar Presentación para
      <strong class="global-form-hint-color">{{ article.DESCRIPCION }}</strong>
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <!--
    <b-field grouped class="special-margin">
      <b-switch v-model="newArticlePresentation.HABMEDVTA" true-value="S" false-value="N">
        Habilita medida para ventas
      </b-switch>
      <b-switch v-model="newArticlePresentation.HABMEDCOM" true-value="S" false-value="N">
        Habilita medida para compras
      </b-switch>
      <b-switch v-model="newArticlePresentation.HABMEDNCREV" true-value="S" false-value="N">
        Habilita medida para nota de credito de venta
      </b-switch>
      <b-switch v-model="newArticlePresentation.HABMEDNCREC" true-value="S" false-value="N">
        Habilita medida para nota de credito de compra
      </b-switch>
    </b-field>
    -->

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre de la presentación (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': articlePresentationRequiredErrors.NOMBRE }"
        :message="{
          'El nombre de la presentación del articulo no es valido':
            articlePresentationRequiredErrors.NOMBRE,
        }"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric-1-circle-outline"
          v-model="newArticlePresentation.NOMBRE"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Medida de la presentación (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': articlePresentationRequiredErrors.MEDIDA }"
        :message="{
          'La medición seleccionada no es valida':
            articlePresentationRequiredErrors.MEDIDA,
        }"
        required
      >
        <b-select
          placeholder="Ejemplo: Metro"
          icon="tape-measure"
          expanded
          v-model="newArticlePresentation.MEDIDA"
        >
          <option value="" disabled>Seleccione una medida</option>
          <option
            v-for="option in measures"
            :value="option"
            :key="option.CLAVE_MED"
          >
            {{ option.DESCRIP_MED }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': articlePresentationRequiredErrors.ESMAYOR }"
        :message="{
          'El valor seleccionado no es valido':
            articlePresentationRequiredErrors.ESMAYOR,
        }"
        required
      >
        <template slot="label">
          Es más grande que la unidad base del artículo (Requerido)
          <b-tooltip
            multilined
            label="Este campo se utilizara para saber que operación realizar al transformar de la unidad base del artículo a la undiad de la presentación"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-select
          placeholder="Ejemplo: Metro"
          icon="perspective-more"
          expanded
          v-model="newArticlePresentation.ESMAYOR"
        >
          <option value="" disabled>Medida</option>
          <option value="S">Si</option>
          <option value="N">No</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': articlePresentationRequiredErrors.FACTOR1 }"
        :message="{
          'El valor de la medida base del articulo no es valido':
            articlePresentationRequiredErrors.FACTOR1,
        }"
      >
        <template slot="label">
          Valor de la medida base del artículo (Requerido)
          <b-tooltip
            multilined
            label="A cuanto equivale la medida base del artículo, si la medida base del artículo son piezas entonces normalmente el valor es 1"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: Normalmente es 1 si la medida base son piezas"
          expanded
          icon="numeric-1-circle-outline"
          type="number"
          step="any"
          v-model="newArticlePresentation.FACTOR1"
          required
        ></b-input>
      </b-field>
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': articlePresentationRequiredErrors.FACTOR2 }"
        :message="{
          'El valor de la nueva presentación no es valido':
            articlePresentationRequiredErrors.FACTOR2,
        }"
      >
        <template slot="label">
          Equivalencia de la presentación en medidas base del artículo
          (Requerido)
          <b-tooltip
            multilined
            label="A cuanto equivale la presentación, por ejemplo si es una caja con 10 piezas y la unidad base del artículo son pizeas entonces la equivalencia sería 10, ya que la caja contiene 10 piezas"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 10 (10 piezas equivalen a una caja)"
          expanded
          icon="numeric-2-circle-outline"
          type="number"
          step="any"
          v-model="newArticlePresentation.FACTOR2"
          required
        ></b-input>
      </b-field>
    </b-field>

    <!--
    <b-field grouped class="special-margin">
  
      <b-field
          label="Estatus de la presentación (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': articlePresentationRequiredErrors.STATUS }"
          :message="{ 'El status de la presentación del articulo no es valido': articlePresentationRequiredErrors.STATUS }">
        <b-select placeholder="Ejemplo: Metro" icon="checkbox-marked-circle-outline" expanded v-model="newArticlePresentation.STATUS">
          <option value="" disabled>Medida</option>
          <option value="A">
            Activo
          </option>
          <option value="S">
            Supendida
          </option>
        </b-select>
      </b-field>
     
      <b-field
        label="Descripción para facturación (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 4"
          expanded
          icon="clipboard"
          v-model="newArticlePresentation.DESCMEDFAC"
        ></b-input>
      </b-field>
      
    </b-field>
     -->

    <!--
    <b-field grouped class="special-margin">
      <b-switch v-model="newArticlePresentation.HABMEDTRAN" true-value="S" false-value="N">
        Habilita medida para transferencias
      </b-switch>
      <b-switch v-model="newArticlePresentation.HABMEDEYS" true-value="S" false-value="N">
        Habilita medida para entradas y salidas
      </b-switch>
      <b-switch v-model="newArticlePresentation.HABMEDENSA" true-value="S" false-value="N">
        Habilita medida para ensables
      </b-switch>
      <b-switch v-model="newArticlePresentation.SIMEDGRAND" true-value="S" false-value="N">
        Es la medida más grande
      </b-switch>
    </b-field>
    -->

    <div class="add-global-controls">
      <b-button type="is-success" @click="addArticlePresentation()"
        >Crear presentación de artículo</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import Cleave from "cleave.js";
import moment from "moment";
// @ is an alias to /src
export default {
  name: "AddArticlePresentation",
  props: ["article"],
  components: {},
  data() {
    return {
      typeSelected: [],
      newArticlePresentation: {
        NOMBRE: "",
        ART: "",
        MEDIDA: "",
        LISTAS_PREC: [],
        PESO: 0,
        VOLUMEN: 0,
        C_BARRAS: "",
        FACTOR1: 1,
        FACTOR2: "",
        ESMAYOR: "S",
        HABMEDVTA: "S",
        HABMEDCOM: "S",
        HABMEDNCREV: "S",
        HABMEDNCREC: "S",
        HABMEDTRAN: "S",
        HABMEDEYS: "S",
        HABMEDENSA: "S",
        SIMEDGRAND: "S",
        STATUS: "A",
        DESCMEDFAC: "",
      },
      articlePresentationRequiredErrors: {
        NOMBRE: false,
        MEDIDA: false,
        FACTOR1: false,
        FACTOR2: false,
        ESMAYOR: false,
        STATUS: false,
      },
    };
  },
  mounted() {
    this.newArticlePresentation.ART = this.article;
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new article presentation in the database
     */
    async addArticlePresentation() {
      try {
        this.articlePresentationRequiredErrors = validateFields(
          this.newArticlePresentation,
          this.articlePresentationRequiredErrors
        );
        if (checkValidationErrors(this.articlePresentationRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEARTICLEPRESENTATION",
            this.newArticlePresentation
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la nueva marca",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar la marca en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la marca en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la marca en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    measures() {
      return this.$store.getters.MEASURES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
