var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar Presentación para "),_c('strong',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(_vm.article.DESCRIPCION))])]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Nombre de la presentación (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.articlePresentationRequiredErrors.NOMBRE },"message":{
        'El nombre de la presentación del articulo no es valido':
          _vm.articlePresentationRequiredErrors.NOMBRE,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Marca 1","expanded":"","icon":"numeric-1-circle-outline","required":""},model:{value:(_vm.newArticlePresentation.NOMBRE),callback:function ($$v) {_vm.$set(_vm.newArticlePresentation, "NOMBRE", $$v)},expression:"newArticlePresentation.NOMBRE"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Medida de la presentación (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.articlePresentationRequiredErrors.MEDIDA },"message":{
        'La medición seleccionada no es valida':
          _vm.articlePresentationRequiredErrors.MEDIDA,
      },"required":""}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Metro","icon":"tape-measure","expanded":""},model:{value:(_vm.newArticlePresentation.MEDIDA),callback:function ($$v) {_vm.$set(_vm.newArticlePresentation, "MEDIDA", $$v)},expression:"newArticlePresentation.MEDIDA"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione una medida")]),_vm._l((_vm.measures),function(option){return _c('option',{key:option.CLAVE_MED,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.DESCRIP_MED)+" ")])})],2)],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.articlePresentationRequiredErrors.ESMAYOR },"message":{
        'El valor seleccionado no es valido':
          _vm.articlePresentationRequiredErrors.ESMAYOR,
      },"required":""}},[_c('template',{slot:"label"},[_vm._v(" Es más grande que la unidad base del artículo (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"Este campo se utilizara para saber que operación realizar al transformar de la unidad base del artículo a la undiad de la presentación"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-select',{attrs:{"placeholder":"Ejemplo: Metro","icon":"perspective-more","expanded":""},model:{value:(_vm.newArticlePresentation.ESMAYOR),callback:function ($$v) {_vm.$set(_vm.newArticlePresentation, "ESMAYOR", $$v)},expression:"newArticlePresentation.ESMAYOR"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Medida")]),_c('option',{attrs:{"value":"S"}},[_vm._v("Si")]),_c('option',{attrs:{"value":"N"}},[_vm._v("No")])])],2)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.articlePresentationRequiredErrors.FACTOR1 },"message":{
        'El valor de la medida base del articulo no es valido':
          _vm.articlePresentationRequiredErrors.FACTOR1,
      }}},[_c('template',{slot:"label"},[_vm._v(" Valor de la medida base del artículo (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"A cuanto equivale la medida base del artículo, si la medida base del artículo son piezas entonces normalmente el valor es 1"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{attrs:{"placeholder":"Ejemplo: Normalmente es 1 si la medida base son piezas","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any","required":""},model:{value:(_vm.newArticlePresentation.FACTOR1),callback:function ($$v) {_vm.$set(_vm.newArticlePresentation, "FACTOR1", $$v)},expression:"newArticlePresentation.FACTOR1"}})],2),_c('b-field',{attrs:{"expanded":"","label-position":"inside","type":{ 'is-danger': _vm.articlePresentationRequiredErrors.FACTOR2 },"message":{
        'El valor de la nueva presentación no es valido':
          _vm.articlePresentationRequiredErrors.FACTOR2,
      }}},[_c('template',{slot:"label"},[_vm._v(" Equivalencia de la presentación en medidas base del artículo (Requerido) "),_c('b-tooltip',{attrs:{"multilined":"","label":"A cuanto equivale la presentación, por ejemplo si es una caja con 10 piezas y la unidad base del artículo son pizeas entonces la equivalencia sería 10, ya que la caja contiene 10 piezas"}},[_c('b-icon',{attrs:{"size":"is-small","icon":"help-circle-outline","custom-class":"input-help-icon"}})],1)],1),_c('b-input',{attrs:{"placeholder":"Ejemplo: 10 (10 piezas equivalen a una caja)","expanded":"","icon":"numeric-2-circle-outline","type":"number","step":"any","required":""},model:{value:(_vm.newArticlePresentation.FACTOR2),callback:function ($$v) {_vm.$set(_vm.newArticlePresentation, "FACTOR2", $$v)},expression:"newArticlePresentation.FACTOR2"}})],2)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addArticlePresentation()}}},[_vm._v("Crear presentación de artículo")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }